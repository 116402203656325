import styled from 'styled-components';
import { Colors } from '../../constants/colors';

const Element = styled.div`
  min-height: 100vh;
  padding: 25px;
  background: linear-gradient(0deg, #D1EDFF, #FFFFFF), linear-gradient(167.99deg, #FFFFFF 0%, #D1EDFF 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

Element.form = styled.form`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

Element.title = styled.div`
  margin-top: 30px;
  width: 100%;
  font-size: 36px;
  line-height: 39px;
  color: #221F20;
`;

Element.input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid ${Colors.grayText};
  border-radius: 5px;
  outline: none;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
`;

Element.fileInputContainer = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #483EE7;
  color: white;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-top: 30px;

  &:hover {
    background-color: #57BBF8;
  }

  &::after {
    content: '+';
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

Element.fileInput = styled.input`
  display: none;
`;

Element.button = styled.button`
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #007bff; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '+';
    margin-right: 8px;
    font-size: 20px;
  }

  &:hover {
    background-color: #57BBF8;
  }
`;

export default Element;