import React from "react";

const images = {
  addDesc: require('./add_desc.png'),
  angles: require('./angles.png'),
  avatar: require('./avatar.png'),
  cameraIcon: require('./camera_icon.png'),
  cancel: require('./cancel.png'),
  closeBtn: require('./close_btn.png'),
  dotsBlue: require('./dots_blue.png'),
  dotsGreen: require('./dots_green.png'),
  dotsWhite: require('./dots_white.png'),
  edit: require('./edit.png'),
  garageBtn: require('./garage_btn.png'),
  gradient: require('./gradient.png'),
  group: require('./group.png'),
  green0: require('./green0.png'),
  green1: require('./green1.png'),
  green2: require('./green2.png'),
  green3: require('./green3.png'),
  green4: require('./green4.png'),
  green5: require('./green5.png'),
  homeSelect: require('./home_select.png'),
  homeBlueBtn: require('./home_blue_btn.png'),
  homeImage: require('./home_image.png'),
  homeListing: require('./home_listing.png'),
  homeLocation: require('./home_location.png'),
  iBackYard: require('./i_back_yard.png'),
  iBathroom: require('./i_bathroom.png'),
  iconRoundColorWhiteBG: require('./icon_round_color_white_bg.png'),
  iGarage: require('./i_garage.png'),
  iKitchen: require('./i_kitchen.png'),
  iLaundry: require('./i_laundry.png'),
  iMBedroom: require('./i_m_bedroom.png'),
  itemCheckBG: require('./item_check_bg.png'),
  itemCheckPhoto: require('./item_check_photo.png'),
  itemDetails: require('./item_details.png'),
  lKitchen: require('./l_kitchen.png'),
  kitchenBtn: require('./kitchen_btn.png'),
  laundryBtn: require('./laundry_btn.png'),
  lLivingRoom: require('./l_living_room.png'),
  lGarage: require('./l_garage.png'),
  lStorage: require('./l_kitchen.png'),
  lOffice: require('./l_office.png'),
  lMainRoom: require('./l_main_room.png'),
  lRooms: require('./l_rooms.png'),
  matchImage: require('./match_image.png'),
  mdName: require('./md_name.png'),
  mockThumb: require('./mock_thumb.png'),
  progress: require('./progress.png'),
  progressIndicator: require('./progress_indicator.png'),
  ring: require('./ring.png'),
  rosieHeaderLogo: require('./rosie_header_logo.png'),
  rosieLogoColor: require('./rosie_logo_color.png'),
  snap: require('./snap.png'),
  suggest: require('./suggest.png'),
};

export default images;