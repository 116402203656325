import styled from 'styled-components'
import { Colors } from '../../constants/colors'

const Element = styled.div`
  min-height: 100vh;
  padding: 25px;
  background: linear-gradient(0deg, #D1EDFF, #FFFFFF), linear-gradient(167.99deg, #FFFFFF 0%, #D1EDFF 100%);
`
Element.form = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
Element.title = styled.div`
  margin-top: 30px;
  width: 100%;
  font-size: 36px;
  line-height: 39px;
  color: #221F20;
`
Element.grid = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: 173px 173px;
`
Element.locationSelect = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  margin-right: auto;
`
Element.listing = styled.div`
  margin-top: 30px;
  font-size: 36px;
  line-height: 39px;
  padding-left: 5px;
  padding-right: 20px;
  color: #221F20;
`
Element.currentLocation = styled.div`
  font-size: 36px;
  line-height: 39px;
  padding-left: 5px;
  padding-right: 10px;
  background: linear-gradient(90deg, #483EE7 0%, #57BBF8 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
`
Element.listingSelected = styled.div`
  margin-top: 30px;
  font-size: 36px;
  line-height: 39px;
  padding-left: 5px;
  padding-right: 20px;
  background: linear-gradient(90deg, #483EE7 0%, #57BBF8 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
`
Element.tabs = styled.div`
  display: flex;
  justifyContent: space-between;
  gap: 12px;
  font-size: 16px;     
  line-height: 20px;
  font-weight: 500;
  background: #D4E9F5;
  margin-top: 25px;
  border-radius: 30px;
  padding: 6px;
  list-style-type: none;
  width: 96%;
`
Element.tabContent = styled.div`
`
Element.tab = styled.div`
  width: 50%;
`
Element.tabLink = styled.div`
  display: inline-block;
  padding-top: 8px;     
  padding-bottom: 8px;
  font-size: 16px;
  color: white;
  background-color: #483EE7;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 800;
  text-align: center;
  width: 100%;
`
Element.tabInactive = styled.div`
  display: inline-block;
  padding-left: 4px;     
  padding-right: 4px;
  padding-top: 8px;     
  padding-bottom: 8px;
  border-radius: 8px;
  font-weight: 800;
  text-decoration: none;
  text-align: center;
  color: ${Colors.grayText};
  width: 100%;
`
Element.modalContent = styled.div`
  height: 100%;
  padding: 20px;
  background: linear-gradient(167.99deg, #D1EDFF 0%, #FFFFFF 100%);
`
Element.modalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`
Element.modalLogo = styled.img`
`
Element.modalHeaderRight = styled.div`
  display: flex;
  gap: 10px;
`
Element.modalProfile = styled.img`
  height: 38px;
  width: 38px;
`
Element.modalForm = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

Element.spaceTile = styled.div`
  height: 78px;
  width: 126px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  border-radius: 8px;
  background-color: white;
  margin-bottom: 8px;
`
Element.spaceTile.title = styled.div`
  color: #221F20;
  font-size: 16px;
  font-weight: 700;
  margin-top: 12px;
`
Element.spaceTile.subtitle = styled.div`
  color: #221F20;
  font-size: 10px;
  font-weight: 400;
`

Element.list = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-top: 10px;
`
Element.assetListItem = styled.div`
  width: 88%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(100, 100, 100, 0.3);
`
Element.assetListItem.content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: center;
`
Element.assetListItem.body = styled.div`
  display: flex;
  gap: 10px;
`
Element.assetListItem.space = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`
Element.assetListItem.title = styled.div`
  color: #221F20;
  font-size: 18px;
  font-weight: 600;
`
Element.assetListItem.subtitle = styled.div`
  color: #221F20;
  font-size: 11px;
  font-weight: 400;
`
Element.assetImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 4px;
`
Element.button = styled.button`
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #007bff; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '+';
    margin-right: 8px;
    font-size: 20px;
  }

  &:hover {
    background-color: #57BBF8;
  }
`;

export default Element