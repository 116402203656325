import { BiCustomize } from 'react-icons/bi'
import { GiBlockHouse, GiHomeGarage } from 'react-icons/gi'
import { MdOutlineBathroom, MdOutlineBedroomParent, MdOutlineSoupKitchen, MdOutlineLocalLaundryService, MdOutlineMeetingRoom } from 'react-icons/md'
import { PiBuildingOffice } from 'react-icons/pi'
import { TbFence, TbBuildingWarehouse } from 'react-icons/tb'

export const getIcon = (name) => {
  return icons[name]
}

const icons = {
  back_yard: TbFence,
  basement: MdOutlineMeetingRoom,
  bathroom: MdOutlineBathroom,
  custom: BiCustomize,
  front_porch: GiBlockHouse,
  garage: GiHomeGarage,
  kitchen: MdOutlineSoupKitchen,
  laundry: MdOutlineLocalLaundryService,
  master_bedroom: MdOutlineBedroomParent,
  main_room: MdOutlineMeetingRoom,
  office: PiBuildingOffice,
  storage: TbBuildingWarehouse,
}