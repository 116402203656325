import './style/style.css'
import {useEffect, useState} from 'react'
import Splash from './screens/SplashScreen'
import Login from './screens/LoginScreen'
import Register from './screens/RegisterScreen'
import Identify from './screens/IdentifyScreen'
import ItemCheck from './screens/ItemCheckScreen'
import ThumbSelect from './screens/ThumbSelectScreen'
import Asset from './screens/AssetScreen'
import Dashboard from './screens/DashboardScreen'
import Location from './screens/LocationScreen'
import BarcodeLabel from './screens/BarcodeLabelScreen'
import NewLocation from './screens/NewLocationScreen'
import {action} from "./state/lib/actions";
import {INIT} from "./state/lib/action_types";
import {useDispatch} from 'react-redux'

function App() {
  const [screen, setScreen] = useState('splash')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(action({type: INIT}))
  }, [])

  const navigate = (target) => {
    console.log('Navigating to', target)
    setScreen(target)
  }

  switch(screen) {
    case 'splash':
      return <Splash navigate={navigate}/>
    case 'register':
      return <Register navigate={navigate}/>
    case 'login':
      return <Login navigate={navigate}/>
    case 'identify':
      return <Identify navigate={navigate}/>
    case 'item_check':
      return <ItemCheck navigate={navigate}/>
    case 'barcode_label':
      return <BarcodeLabel navigate={navigate}/>
    case 'thumb_select':
      return <ThumbSelect navigate={navigate}/>
    case 'asset':
      return <Asset navigate={navigate}/>
    case 'dashboard':
      return <Dashboard navigate={navigate}/>
    case 'location':
      return <Location navigate={navigate}/>
      case 'new_location':
        return <NewLocation navigate={navigate}/>
    default:
      return <Login navigate={navigate}/>
  }
}

export default App
