import '../style/style.css'
import React from 'react'
import Images from '../assets/images/images'
import Element from '../style/screens/location_screen_styles'

const LocationScreen = ({navigate}) => {
  return (
    <Element>
      <Element.header>
        <Element.header.logo src={Images.rosieLogoColor}/>
        <Element.header.right>
          <Element.header.home src={Images.iconRoundColorWhiteBG}/>
          <Element.header.profile src={Images.avatar}/>
        </Element.header.right>
      </Element.header>
      <Element.form>
        <img src={Images.homeLocation}/>
        <Element.photo>
          <Element.pic src={Images.homeImage}/>
          <Element.picEdit src={Images.edit}/>
        </Element.photo>
        <Element.input id='username' type='text' placeholder='Address'/>
        <Element.title>Rooms</Element.title>
        <img src={Images.lKitchen}/>
        <img src={Images.lLivingRoom}/>
        <img src={Images.lGarage}/>
        <img src={Images.lStorage}/>
        <img src={Images.lOffice}/>
        <img src={Images.lMainRoom}/>
        <img src={Images.lRooms}/>
        <Element.btnDark type='button'>
          <Element.btnContent>
            <span>Save Changes</span>
          </Element.btnContent>
        </Element.btnDark>
        <Element.btnLight type='button'>
          Remove this Location
        </Element.btnLight>
        </Element.form>
        </Element>
      )
    }
export default LocationScreen
