export const State = {
  assetImageCount: 0,
  assetMatch: false,
  assetThumbnail: null,
  assetThumbnailUrl: null,
  barcodeImage: null,
  barcodeLabelScreenStep: '5',
  barcodeScanSkipped: false,
  barcodeScanTaken: false,
  images: [],
  imageUrls: [],
  isMobile: false,
  labelImage: null,
  labelScanSkipped: false,
  labelScanTaken: false,
  locations: [],
  matchingAssetId: null,
  matchingAssetImageUrl: null,
}