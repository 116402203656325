import React, {useEffect, useState} from 'react'
import { ReactComponent as RosieLogo } from '../assets/svg/rosie_vision_logo.svg'
import Element from '../style/screens/register_screen_styles'
import {action} from '../state/lib/actions'
import {useSelector, useDispatch} from 'react-redux'
import {REGISTER} from '../state/lib/action_types'
import '../style/style.css'


const RegisterScreen = ({navigate}) => {
  const user = useSelector((state) => state.app.user)
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
  })
  const dispatch = useDispatch()

  const register = () => {
    if (fields.email && fields.password && fields.firstName && fields.lastName && fields.username) {
      dispatch(action({type: REGISTER, data: fields}))
    }
  }

  const handleTextInputChange = (fieldName, text) => {
    const updatedFields = fields
    updatedFields[fieldName] = text
    setFields(updatedFields)
  }
  useEffect(() => {
    if (user) {
      setTimeout(() => navigate('dashboard'), 1000)
    }
  }, [user])

  return (
    <div className='login'>
      <Element.header><RosieLogo/></Element.header>
      <Element.textInput
        type='text'
        placeholder='First Name'
        onChange={e => handleTextInputChange('firstName', e.target.value)}
      />
      <Element.textInput
        type='text'
        placeholder='Last Name'
        onChange={e => handleTextInputChange('lastName', e.target.value)}
      />
      <Element.textInput
        type='text'
        placeholder='Username'
        onChange={e => handleTextInputChange('username', e.target.value)}
      />
      <Element.textInput
        type='text'
        placeholder='Email'
        onChange={e => handleTextInputChange('email', e.target.value)}
      />
      <Element.textInput
        type='password'
        placeholder='Password'
        onChange={e => handleTextInputChange('password', e.target.value)}
      />
      <Element.submit onClick={register}>Register</Element.submit>

    </div>
  )
}

export default RegisterScreen