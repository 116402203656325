import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';
import { action } from '../state/lib/actions'
import { UPLOAD_IMAGE } from '../state/lib/action_types'
import { HiOutlineExclamationCircle } from "react-icons/hi";

import Webcam from 'react-webcam'
import '../style/style.css'
import Images from '../assets/images/images'
import Element from '../style/screens/barcode_label_screen_styles'
import { State } from '../state/state'
import { IMAGE_TYPE_BARCODE, IMAGE_TYPE_LABEL } from '../constants/constants'

const titles = {
  '5': 'Let\'s Scan the barcode',
  '6': 'Let\'s scan the product label'
}

const subtitles = {
  '5': 'This will help us collect the most accurate information about your item',
  '6': 'Donec id elit non mi porta gravida at eget metus. Nullam quis risus eget urna.'
}

const BarcodeLabelScreen = ({ navigate }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.app.user)
  const webcamRef = useRef(null)
  const [showFlash, setShowFlash] = useState(false)

  const imageTypeLabel = State.barcodeLabelScreenStep === '5' ? 'barcode' : 'product label';

  const videoConstraints = State.isMobile ? {
    facingMode: { exact: 'environment' },
    aspectRatio: 1.7778 // 16:9 aspect ratio
  } : {
    facingMode: 'user',
    aspectRatio: 1.7778 // 16:9 aspect ratio
  }

  const cssClass = (prefix) => {
    return prefix + State.barcodeLabelScreenStep
  }

  const flash = () => {
    setShowFlash(true)
    setTimeout(() => setShowFlash(false), 50)
  }

  const cancelScan = () => {
    if (State.barcodeLabelScreenStep === '5') {
      State.barcodeScanSkipped = true
    }
    else {
      State.labelScanSkipped = true
    }
    navigate('item_check')
  }

  const captureImages = async () => {
    if (!webcamRef.current) {
      console.log('no webcam ref');
      return
    }
    flash()

    const imageData = webcamRef.current.getScreenshot()
    if (!imageData) {
      console.log('no image found');
      return
    }

    State.images.push(imageData)
    const filename = `${user?.id}_${uuidv4()}.jpg`
    const data = {
      userId: user?.id,
      container: 'mluploadedimages',
      filename,
      imageType: State.barcodeLabelScreenStep === '5' ? IMAGE_TYPE_BARCODE : IMAGE_TYPE_LABEL,
      imageData
    }
    if (State.barcodeLabelScreenStep === '5') {
      State.barcodeScanSkipped = false
      State.barcodeScanTaken = true
    }
    else {
      State.labelScanSkipped = false
      State.labelScanTaken = true
    }
    dispatch(action({ type: UPLOAD_IMAGE, data }))
    navigate('item_check')
  }

  return (
    <Element>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat='image/jpeg'
        videoConstraints={videoConstraints}
        className={navigator.userAgentData === undefined ? 'webcam-mobile' : 'webcam'}
      />
      <div className='rect-overlay'></div>
      <Element.topLayer>
        <div className='flash' hidden={showFlash === false} />
        <Element.header>
          <Element.header.logo src={Images.rosieHeaderLogo} />
          <Element.header.right>
            <Element.header.home src={Images.homeBlueBtn} onClick={() => navigate('dashboard')} />
            <Element.header.profile src={Images.avatar} />
          </Element.header.right>
        </Element.header>
        <div className={cssClass('i-title-')}>{titles[State.barcodeLabelScreenStep]}</div>
        <div className={cssClass('i-subtitle-container-')}>
          <Element.subtitle>{subtitles[State.barcodeLabelScreenStep]}</Element.subtitle>
        </div>
        <Element.controls>
          <Element.rosieBtn
            src={Images.iconRoundColorWhiteBG}
            onClick={captureImages}
          />
          <Element.btnRow onClick={cancelScan}>
            <HiOutlineExclamationCircle className="cant-find-text" />
            <Element.cantFindText>I can't find a {imageTypeLabel}</Element.cantFindText>
          </Element.btnRow>
        </Element.controls>
      </Element.topLayer>
    </Element>
  )
}

export default BarcodeLabelScreen