import React from 'react'
import '../style/style.css'
import Element from '../style/screens/splash_screen_styles'
import { useSelector } from 'react-redux'

import { ReactComponent as RosieIcon } from '../assets/svg/rosie_icon.svg'

const SplashScreen = ({navigate}) => {
  const user = useSelector((state) => state.app.user)
  const target = user ? 'dashboard' : 'login'
  setTimeout(() => navigate(target), 500)
  return (
    <div className='splash'>
      <Element.rosieIcon><RosieIcon/></Element.rosieIcon>
    </div>
  )
}

export default SplashScreen