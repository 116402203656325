import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import configureStore from './state/lib/create_store'
import './index.css'
import App from './App'
import { State } from './state/state'

const isMobile = /Android|iPhone/i.test(navigator.userAgent);
State.isMobile = isMobile;
console.log('================  IS MOBILE? ' + State.isMobile)

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);