import styled from 'styled-components'

Element = styled.div`
  display: flex;
  justify-content: space-between;
`
Element.logo = styled.img`
`
Element.right = styled.div`
  display: flex;
  gap: 10px;
`
Element.navBtn = styled.img`
  height: 40px;
  width: 40px;
`
Element.profile = styled.img`
  height: 38px;
  width: 38px;
`

export default Element
