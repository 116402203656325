import styled from 'styled-components'

const Element = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url('../../assets/images/gradient.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
Element.header = styled.div`
  padding-top: 30px;
  padding-bottom: 40px;
`
Element.textInput = styled.input`
  width: 250px;
  padding: 12px 8px 12px 8px;
  border-radius: 8px;
  font-size: 14px;
  border-color: lightblue;
`
Element.submit = styled.button`
  color: white;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  width: 270px;
  padding: 12px 8px 12px 8px;
  border-radius: 8px;
  background-color: #483EE7;
`

export default Element