import styled from 'styled-components'
import { State } from '../../state/state'

const Element = styled.div`
  min-height: 100vh;
 `
Element.header = styled.div`
  display: flex;
  justify-content: space-between;
`
Element.header.logo = styled.img`
`
Element.header.right = styled.div`
  display: flex;
  gap: 10px;
`
Element.header.home = styled.img`
  height: 40px;
  width: 40px;
`
Element.header.profile = styled.img`
  height: 38px;
  width: 38px;
`
Element.subtitle = styled.div`
  text-align: center;
  color: #E6E6E6;
  font-size: 16px;
  line-height: 24px;
  padding-left: 40px;
  padding-right: 40px;
`
Element.controls = styled.div`
  margin-top: ${navigator.userAgentData === undefined ? '195px' : '350px'};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
`
Element.rosieBtn = styled.img`
  height: 80px;
  width: 80px;
`
Element.btnRow = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  width: 100%;
`
Element.cantFindText = styled.div`
   color: white;
`
Element.topLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 20;
`

export default Element