import { put, call, takeLatest } from 'redux-saga/effects'
import API from './api'
import { actionSuccess, actionError } from './lib/actions'
import {State} from '../state/state'
import {CREATE_ASSET,  INIT, GET_ASSET, GET_FULL_USER_LOCATIONS, LOGIN,
        REGISTER, UPDATE_ASSET, UPLOAD_IMAGE, ADD_LOCATION} from './lib/action_types'
import {STATUS_AUTHENTICATED} from '../constants/constants'

export const createAsset = function* (action) {
  console.log('IN createAsset() - action', action)
  const { type } = action
  State.assetMatch = false;
  try {
    const data = yield call(API.createAsset, action.data)
    console.log('IN createAsset() - data: ', data)
    yield put(actionSuccess(data, type))
  } catch (error) {
    yield put(actionError(error, type))
  }
}
export const watchCreateAsset = function* () {
  yield takeLatest(CREATE_ASSET, createAsset)
}

export const init = function* (action) {
  console.log('IN init() - action', action)
  const { type } = action
  try {
    const data = yield call(API.init, action)
    console.log('IN init() - data: ', data)
    yield put(actionSuccess(data, type))
  } catch (error) {
    yield put(actionError(error, type))
  }
}
export const watchInit = function* () {
  yield takeLatest(INIT, init)
}

export const getAsset = function* (action) {
  console.log('IN getAsset() - action', action)
  const { type } = action
  try {
    const data = yield call(API.getAsset, action.data.id)
    console.log('IN getAsset() - data: ', data)
    yield put(actionSuccess(data, type))
  } catch (error) {
    yield put(actionError(error, type))
  }
}
export const watchGetAsset = function* () {
  yield takeLatest(GET_ASSET, getAsset)
}

export const getFullUserLocations = function* (action) {
  console.log('IN getFullUserLocations() - action', action)
  const { type } = action
  try {
    const data = yield call(API.getFullUserLocations, action.data.userId)
    console.log('IN getFullUserLocations() - data: ', data)
    yield put(actionSuccess(data, type))
  } catch (error) {
    yield put(actionError(error, type))
  }
}
export const watchGetFullUserLocations = function* () {
  yield takeLatest(GET_FULL_USER_LOCATIONS, getFullUserLocations)
}

export const login = function* (action) {
  console.log('IN login()')
  const { type } = action
  try {
    const data = yield call(API.login, action.data)
    if (data.authStatus === STATUS_AUTHENTICATED) {
      yield put(actionSuccess(data, type))
    }
  } catch (error) {
    console.log('ERROR: ' + error)
    yield put(actionError(error, type))
  }
}
export const watchLogin = function* () {
  yield takeLatest(LOGIN, login)
}

export const register = function* (action) {
  console.log('IN register() - action', action)
  const { type } = action
  try {
    const data = yield call(API.register, action.data)
    console.log('IN register() - data: ', data)
    yield put(actionSuccess(data, type))
    const data2 = yield call(API.getFullUserLocations, data.id)
    console.log('IN register() - data2: ', data2)
    yield put(actionSuccess(data2, GET_FULL_USER_LOCATIONS))
  } catch (error) {
    yield put(actionError(error, type))
  }
}
export const watchRegister = function* () {
  yield takeLatest(REGISTER, register)
}

export const updateAsset = function* (action) {
  console.log('IN updateAsset() - action', action)
  const { type } = action
  State.assetMatch = false;
  try {
    const data = yield call(API.updateAsset, action.data)
    console.log('IN updateAsset() - data: ', data)
    yield put(actionSuccess(data, type))
  } catch (error) {
    yield put(actionError(error, type))
  }
}
export const watchUpdateAsset = function* () {
  yield takeLatest(UPDATE_ASSET, updateAsset)
}

export const uploadImage = function* (action) {
  console.log('IN uploadImage() - action', action)
  const { type } = action
  try {
    const data = yield call(API.uploadImage, action.data)
    console.log('IN uploadImage() - data: ', data)
    console.log('IN uploadImage() - data.matchingAssetId: ', data.asset_id)
    // if (data.assetMatch) {
    //   State.assetMatch = true;
    //   State.matchingAssetId = data.asset_id;
    // }
    yield put(actionSuccess(data, type))
  } catch (error) {
    yield put(actionError(error, type))
  }
}
export const watchUploadImage = function* () {
  yield takeLatest(UPLOAD_IMAGE, uploadImage)
}

function* handleAddLocation(action) {
  try {
    const response = yield call(API.addLocation, action.payload);
    yield put(actionSuccess(response, ADD_LOCATION));
  } catch (error) {
    yield put(actionError(error, ADD_LOCATION));
  }
}

export function* watchAddLocation() {
  yield takeLatest(ADD_LOCATION, handleAddLocation);
}