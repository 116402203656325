import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {action} from '../state/lib/actions'
import {CREATE_ASSET, UPDATE_ASSET} from '../state/lib/action_types'
import Images from '../assets/images/images'
import Element from '../style/screens/asset_screen_styles'
import {State} from '../state/state'
import Header from '../components/Header'
import '../style/style.css'
import {getIcon} from "../constants/icons";

const AssetScreen = ({navigate}) => {

  const dispatch = useDispatch()
  const user = useSelector((state) => state.app.user)
  const locations = useSelector((state) => state.app.locations)
  const currentAsset = useSelector((state) => state.app.currentAsset)
  const [currentLocationId, setCurrentLocationId] = useState(null)
  const [currentLocation, setCurrentLocation] = useState(locations.find((l) => l.id == currentLocationId))
  const [selectedSpaceId, setSelectedSpaceId] = useState(null)

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  const [fields, setFields] = useState({
    name: '',
    purchaseDate: '',
    modelInfo: '',
    serialNumber: '',
    description: '',
  })

  useEffect(() => {
    setFields({
      name: currentAsset?.name,
      purchaseDate: formatDate(currentAsset?.purchaseDate),
      modelInfo: currentAsset?.modelInfo,
      serialNumber: currentAsset?.serialNumber,
      description: currentAsset?.description
    })
    setSelectedSpaceId(currentAsset?.spaceId)
    setCurrentLocationId(currentAsset?.locationId)
    setCurrentLocation(locations.find((l) => l.id == currentLocationId ) || locations[0])
  }, [currentAsset])

  const createAsset = () => {
    const data = {
      userId: user?.id,
      purchaseDate: fields.purchaseDate,
      modelInfo: fields.modelInfo,
      name: fields.name,
      serialNumber: fields.serialNumber,
      description: fields.description,
      thumbnail: State.assetThumbnailUrl,
      locationId: currentLocation?.id,
      spaceId: selectedSpaceId,
    }
    dispatch(action({type: CREATE_ASSET, data}))
    navigate('dashboard')
  }

  const updateAsset = () => {
    const data = {
      id: State.matchingAssetId,
      userId: user?.id,
      name: fields?.name,
      purchaseDate: fields?.purchaseDate,
      modelInfo: fields?.modelInfo,
      serialNumber: fields?.serialNumber,
      description: fields?.description,
      thumbnail: State.assetThumbnailUrl,
      locationId: currentLocation?.id,
      spaceId: selectedSpaceId,
    }
    dispatch(action({type: UPDATE_ASSET, data}))
    navigate('dashboard')
  }

  const selectLocation = (id) => {
    setSelectedSpaceId(null)
    setCurrentLocationId(id)
    setCurrentLocation(locations.find((l) => l.id == id ))
  }

  const handleTextInputChange = async (fieldName, text) => {
    const updatedFields = fields
    updatedFields[fieldName] = text
    await setFields(updatedFields)
  }

  const SpaceTile = ({title, subtitle, icon, setSelectedSpaceId, selected, id}) => {
    const Icon = getIcon(icon)
    return (
      <Element.spaceTile selected={selected} onClick={() => setSelectedSpaceId(id)}>
        <Icon size='24px' color='#57BBF8'/>
        <Element.spaceTile.title selected={selected}>{title}</Element.spaceTile.title>
        <Element.spaceTile.subtitle selected={selected}>{subtitle}</Element.spaceTile.subtitle>
      </Element.spaceTile>
    )
  }

  return (
    <Element>
      <Header navigate={navigate} screen='asset'/>
      <Element.form>
        <Element.title>Add some details to your item</Element.title>
        <Element.subtitle>
          We’ve got you started with some of the information we could gather.
        </Element.subtitle>

        <Element.photo>
          <Element.pic className='item-confirm' src={State.assetThumbnailUrl}/>
          <Element.picEdit src={Images.edit}/>
        </Element.photo>
        {/*
        <img src={Images.mdName}/>
        <img src={Images.suggest}/>
        */}
        <Element.textInput
          type="text" placeholder="Name"
          onChange={e => handleTextInputChange('name', e.target.value)}
          defaultValue={fields.name}
        />
        <Element.textInput
          type="text" placeholder="Purchase Date"
          onChange={e => handleTextInputChange('purchaseDate', e.target.value)}
          defaultValue={fields.purchaseDate}
        />
        <Element.textInput
          type="text" placeholder="Model Information"
          onChange={e => handleTextInputChange('modelInfo', e.target.value)}
          defaultValue={fields.modelInfo}
        />
        <Element.textInput
          type="text" placeholder="Serial Number"
          onChange={e => handleTextInputChange('serialNumber', e.target.value)}
          defaultValue={fields.serialNumber}
        />
        <Element.textArea
          rows={4}
          type="text" placeholder="Description"
          onChange={e => handleTextInputChange('description', e.target.value)}
          defaultValue={fields.description}
        ></Element.textArea>

        <div>Where is this located?</div>
        <Element.mdSelect onChange={(e) => selectLocation(e.target.value)}>
          {locations.map((location) => {
           return <Element.mdOption key={location.id} value={location.id}>{location.name}</Element.mdOption>
          })}
        </Element.mdSelect>

        <Element.grid>
          {locations.find((l) => l.id == currentLocation?.id )?.spaces.map(space => {
            const assetCount = space.assets.length
            const subtitle = assetCount + ' ' + (assetCount !== 1 ? 'items' : 'item')
            return <SpaceTile
              key={space?.id}
              id={space?.id}
              title={space?.name}
              subtitle={subtitle}
              icon={space?.type}
              selected={selectedSpaceId === space?.id}
              setSelectedSpaceId={setSelectedSpaceId}
            />
          })
          }
        </Element.grid>

        {State.matchingAssetId ? (
          <Element.btnDark type='button' onClick={updateAsset}>
            <Element.btnContent>
              <span>Update Asset</span>
            </Element.btnContent>
          </Element.btnDark>
        ) : (
          <Element.btnDark type='button' onClick={createAsset}>
            <Element.btnContent>
              <span>Add to Inventory</span>
            </Element.btnContent>
          </Element.btnDark>
        )
        }

      </Element.form>
    </Element>
  )
}

export default AssetScreen
