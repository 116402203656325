import styled from 'styled-components'
import { Colors } from '../../constants/colors'

const Element = styled.div`
  min-height: 100vh;
  padding: 20px;
`
Element.header = styled.div`
  display: flex;
  justify-content: space-between;
`
Element.header.logo = styled.img`
`
Element.header.right = styled.div`
  display: flex;
  gap: 10px;
`
Element.header.home = styled.img`
  height: 40px;
  width: 40px;
`
Element.header.profile = styled.img`
  height: 38px;
  width: 38px;
`
Element.form = styled.div`
  margin-top: 40px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`
Element.title = styled.div`
  font-size: 36px;
  line-height: 39px;
  text-align: center;
  color: #221F20;
`
Element.subtitle = styled.div`
  margin-top: 5px;
  margin-bottom: 30px;
  margin-left: 3px;
  margin-right: 3px;
  color: #413E3F;
  text-align: center;
`
Element.photo = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`
Element.btnDark = styled.button`
  background: #483EE7;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 20px;
  width: 335px;
`
Element.btnContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`
Element.btnLight = styled.button`
  background: white;
  width: 335px;
  border: 1px solid #57BBF8;
  border-radius: 8px;
  color: #221F20;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
`
Element.thumb = styled.img`
  border-radius: 50%;
`

export default Element;