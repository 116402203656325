import '../style/style.css'
import React, {useEffect} from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import Images from '../assets/images/images'
import Element from '../style/screens/thumb_select_screen_styles'
import "swiper/css";
import {State} from '../state/state'
/*
   <SwiperSlide><Element.thumb src={Images.itemCheckPhoto}/></SwiperSlide>
 */

const ThumbSelectScreen = ({navigate}) => {

  let images = []

  useEffect(() => {
    images = State.images.slice(0,  State.assetImageCount);
  }, [])


  const handleSlideChange = (swiper) => {
    console.log('Slide changed to index:', swiper.activeIndex);
    State.assetThumbnail = State.images[swiper.activeIndex]
    State.assetThumbnailUrl = State.imageUrls[swiper.activeIndex]
  };

  const saveThumbnail = () => {
    navigate('asset')
  }

  return (
    <Element>
      <Element.header>
        <Element.header.logo src={Images.rosieLogoColor} />
        <Element.header.right>
          <Element.header.home src={Images.homeBlueBtn} />
          <Element.header.profile src={Images.avatar} />
        </Element.header.right>
      </Element.header>

      <Element.form>
        <Element.title>Select a thumbnail for your item</Element.title>
        <Element.subtitle>
          Choose an image to represent this item within your inventory. You can change it later, too.
        </Element.subtitle>
        <Swiper onSlideChange={handleSlideChange}>
          {State.images.map((image) => <SwiperSlide><Element.thumb src={image}/></SwiperSlide>)}
        </Swiper>
        <Element.btnDark type='button' onClick={saveThumbnail}>
          <Element.btnContent>
            <span>Select Photo</span>
          </Element.btnContent>
        </Element.btnDark>
        <Element.btnLight type='button'>
          Choose New Photo
        </Element.btnLight>
      </Element.form>
    </Element>
  )
}

export default ThumbSelectScreen
