export const CREATE_ASSET = 'CREATE_ASSET'
export const INIT = 'INIT'
export const GET_ASSET = 'GET_ASSET'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const GET_FULL_USER_LOCATIONS = 'GET_FULL_USER_LOCATIONS'
export const REGISTER = 'REGISTER'
export const UPDATE_ASSET = 'UPDATE_ASSET'
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const CLEAR_BARCODE_LABEL = 'CLEAR_BARCODE_LABEL'
export const ADD_LOCATION = 'ADD_LOCATION'
export const DELETE_LOCATION = 'DELETE_LOCATION'
