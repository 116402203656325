import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';
import { action } from '../state/lib/actions'
import { UPLOAD_IMAGE, GET_ASSET, CLEAR_BARCODE_LABEL } from '../state/lib/action_types'
import Webcam from 'react-webcam'
import Modal from 'react-modal'
import compare from 'ssim.js'
import Header from '../components/Header'
import Images from '../assets/images/images'
import Element from '../style/screens/identify_screen_styles'
import { loadImage } from '../util/imageUtil'
import { State } from '../state/state'
import { IMAGE_TYPE_DEFAULT } from '../constants/constants'
import '../style/style.css'

const INITIAL_STATE = '1'
const IMAGE_LIMIT = 5

const titles = {
  '1': 'Get started by identifying an item',
  '2': 'Scanning area for an item',
  '3': 'Item detected.  Hold camera steady.',
  '3a': 'Item detected.  Hold camera steady.',
  '4': 'Let\'s get some additional angles',
}

const subtitles = {
  '1': 'Tap the icon below to begin analyzing your item.',
  '2': 'Make sure it\'s in focus and clear of obstacles.',
  '3': 'We\'re comparing this against our database for item identification.',
  '3a': 'We\'re comparing this against our database for item identification.',
  '4': 'Move your camera around so we can see a little more about your object.',
}


const IdentifyScreen = ({ navigate }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.app.user)
  const identify = useSelector((state) => state.app.identify)
  const webcamRef = useRef(null)

  const [canCapture, setCanCapture] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [step, setStep] = useState(INITIAL_STATE)
  const [showFlash, setShowFlash] = useState(false)

  const videoConstraints = State.isMobile ? {
    facingMode: { exact: 'environment' },
    aspectRatio: 1.7778 // 16:9 aspect ratio
  } : {
    facingMode: 'user',
    aspectRatio: 1.7778 // 16:9 aspect ratio
  }

  useEffect(() => {
    clearImages()
    dispatch(action({ type: CLEAR_BARCODE_LABEL, data: {} }))
  }, [])


  const cssClass = (prefix) => {
    return prefix + step
  }

  const clearImages = () => {
    State.images = []
    State.imageUrls = []
  }

  const cancelScan = () => {
    setStep('1')
    setCanCapture(false)
    clearImages()
  }

  const dotImage = () => {
    if (step === '1') {
      return Images.dotsWhite
    }
    if (step === '2' || step === '4' || step === '4a') {
      return Images.dotsBlue
    }
    if (step === '3') {
      return Images.dotsGreen
    }

  }

  const flash = () => {
    setShowFlash(true)
    setTimeout(() => setShowFlash(false), 50)
  }

  const imageCaptureStep = () => {
    setStep('2')
    setTimeout(() => setStep('3'), 2000)
    setCanCapture(true)
    setTimeout(() => captureImages(), 3000)
  }

  const handleKeepScanning = () => {
    setModalIsOpen(false)
    cancelScan()
  }

  const handleMatch = () => {
    State.assetImageCount = State.images.length
    setModalIsOpen(false)
    navigate('asset')
  }

  const handleNoMatch = () => {
    State.assetImageCount = State.images.length
    navigate('item_check')
  }

  const captureImages = async () => {
    // if (!canCapture) { return }
    if (!webcamRef.current) {
      console.log('no webcam ref');
      return
    }
    let isMatching = false

    // 1) Take screenshot
    const imageData = webcamRef.current.getScreenshot()
    if (!imageData) {
      console.log('no image found');
      return
    }

    // 2) Compare screenshot to stored screenshots
    if (State.images.length > 0) {
      const currentImage = await loadImage(imageData)
      for (const imgSrc of State.images) {
        const img = await loadImage(imgSrc)
        const { mssim } = compare(currentImage, img)
        console.log('MSSIM: ' + mssim)
        if (mssim > 0.98) {
          isMatching = true
          break
        }
      }
    }

    // 3) Add image to array and upload to backend if it is unique enough
    console.log('Image matching previous local images?: ' + isMatching)
    if (!isMatching || !State.images.length) {
      flash()
      setStep('3a')
      State.images.push(imageData)
      const filename = `${user?.id}_${uuidv4()}.jpg`
      const data = { userId: user?.id, container: 'mluploadedimages', filename, imageType: IMAGE_TYPE_DEFAULT, imageData }
      dispatch(action({ type: UPLOAD_IMAGE, data }))
      setTimeout(() => setStep('4'), 1000)
    }

    console.log('================== assetMatch: ' + State.assetMatch)

    // 4) Finish if we have a matching asset
    if (State.assetMatch) {
      dispatch(action({ type: GET_ASSET, data: { id: State.matchingAssetId } }))
      setTimeout(() => setModalIsOpen(true), 3000)
      return
    }

    // 5) Repeat until we have 5 images
    console.log('CURRENT IMAGES ARRAY LENGTH: ' + State.images.length)
    if (State.images.length < IMAGE_LIMIT) {
      setTimeout(() => captureImages(), 3000)
    } else {
      setTimeout(() => handleNoMatch(), 2000)
    }
  }

  return (
    <>
      <Element>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat='image/jpeg'
          videoConstraints={videoConstraints}
          className={navigator.userAgentData === undefined ? 'webcam-mobile' : 'webcam'}
        />

        <div className='circle-overlay'></div>

        <Element.topLayer>
          <div className='flash' hidden={showFlash === false} />
          <Header navigate={navigate} screen='identify' />

          <div className={cssClass('i-title-')}>{titles[step]}</div>
          <div className={cssClass('i-subtitle-container-')}>
            <Element.subtitle>{subtitles[step]}</Element.subtitle>
          </div>

          <Element.rosieBtn
            src={Images.iconRoundColorWhiteBG}
            onClick={() => imageCaptureStep()}
            hidden={step !== '1'}
          />

          <Element.progressContainer hidden={step !== '3'}>
            <Element.progressCircle src={Images.progress} />
            <Element.progressCircleIndicator src={Images.progressIndicator} />
          </Element.progressContainer>

          <Element.progressContainer hidden={step !== '3a' && step !== '4'}>
            <Element.thumbnail src={State.images[State.images.length - 1]} />
          </Element.progressContainer>

          <Element.progressContainer hidden={true}>
            <Element.progressCircle src={Images.angles} />
            <Element.progressCircleIndicator src={Images.group} />
            <Element.progressCircleRing src={Images.ring} />
          </Element.progressContainer>

          <Element.dots src={dotImage()} />

          <Element.footer>
            <img
              src={Images.cancel}
              onClick={() => cancelScan()}
              hidden={step === '1'}
            />
            <Element.snapWrapper>
              <Element.snapImage
                src={Images.snap}
                hidden={step === '1'}
                onClick={() => {
                }}
              />
              <Element.snapProgress src={Images[`green${State.images.length}`]} />
            </Element.snapWrapper>
          </Element.footer>
        </Element.topLayer>
      </Element>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel='Image Data'
        overlayClassName='i-modal-overlay'
        className='i-modal'
      >
        <Element.modalContent>
          <Element.form>
            <img className='item-confirm' src={State.assetThumbnailUrl} />
            <Element.modalTitle>Is This The Same Item?</Element.modalTitle>
            <Element.modalSubtitle>
              We think we may have found this
              already in your inventory.
            </Element.modalSubtitle>
            <Element.btnDark type='button' onClick={() => handleMatch()}>
              <Element.btnContent>
                <span>Yes, it’s the same</span>
              </Element.btnContent>
            </Element.btnDark>
            <Element.btnLight type='button' onClick={() => handleKeepScanning()}>
              No, keep scanning
            </Element.btnLight>
            <Element.btnLight type='button' onClick={() => handleNoMatch()}>
              Enter asset information
            </Element.btnLight>
          </Element.form>
        </Element.modalContent>
      </Modal>
    </>
  )
}

export default IdentifyScreen