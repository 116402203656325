import { ERROR, SUCCESS } from '../../constants/constants'
import { ADD_LOCATION } from './action_types'; // Ensure this import is present

export const action = (payload) => {
  const { type, data } = payload
  return {
    type,
    data,
  }
}
export const actionSuccess = (data, type) => ({
  type: `${type}-${SUCCESS}`,
  data,
})
export const actionError = (error, type) => ({
  type: `${type}-${ERROR}`,
  error,
})

// Action creator for adding a location
export const addLocation = (location) => ({
  type: ADD_LOCATION,
  payload: location,
});
