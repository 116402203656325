import React from "react";
import Element from "../style/components/header_styles";
import {LOGIN, LOGOUT, GET_FULL_USER_LOCATIONS} from "../state/lib/action_types";

import Images from "../assets/images/images";
import {useDispatch, useSelector} from "react-redux";
import {action} from "../state/lib/actions";

const Header = ({navigate, screen}) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.app.user)

  const logout = () => {
    dispatch(action({ type: LOGOUT }))
    setTimeout(() =>  navigate('login'), 1000)
  }

 const getLogo = () => {
   if(screen === 'dashboard') return Images.rosieLogoColor
   if(screen === 'identify') return Images.rosieHeaderLogo
   if(screen === 'asset') return Images.rosieLogoColor
 }

  const getNavBtn = () => {
    if(screen === 'dashboard') return Images.iconRoundColorWhiteBG
    if(screen === 'identify') return Images.homeBlueBtn
    if(screen === 'asset') return Images.homeBlueBtn
  }

  const getNavTarget = () => {
    dispatch(action({type: GET_FULL_USER_LOCATIONS, data: {userId: user?.id}}))
    if(screen === 'dashboard') return 'identify'
    if(screen === 'identify') return 'dashboard'
    if(screen === 'asset') return 'dashboard'
  }

  return (
    <Element>
      <Element.logo src={getLogo()} />
      <Element.right>
        <Element.navBtn src={getNavBtn()} onClick={() => navigate(getNavTarget())}/>
        <Element.profile src={Images.avatar} onClick={logout}/>
      </Element.right>
    </Element>

  )
}

export default Header