import React from 'react'
import { FaCheck, FaPlus, FaBan } from "react-icons/fa";
import Images from '../assets/images/images'
import Element from '../style/screens/item_check_screen_styles'
import {State} from '../state/state'
import { IMAGE_TYPE_BARCODE, IMAGE_TYPE_LABEL } from '../constants/constants'
import '../style/style.css'

const getIcon = (imageType) => {
  if (imageType === IMAGE_TYPE_BARCODE) {
    if (State.barcodeScanSkipped) return <FaBan/>
    if (!State.barcodeScanTaken) return <FaPlus/>
    if (State.barcodeScanTaken) return <FaCheck/>
  }
  if (imageType === IMAGE_TYPE_LABEL) {
    if (State.labelScanSkipped) return <FaBan/>
    if (!State.labelScanTaken) return <FaPlus/>
    if (State.labelScanTaken) return <FaCheck/>
  }
}

const isSubmitDisabled = () => {
  if (
    (!State.barcodeScanTaken && !State.barcodeScanSkipped) ||
    (!State.labelScanTaken && !State.labelScanSkipped)
  ) {
    return true
  }
  return false
}


const ItemCheckScreen = ({navigate}) => {

  const navigateToScan = (imageType) => {
     State.barcodeLabelScreenStep = imageType === IMAGE_TYPE_BARCODE ? '5' : '6'
     navigate('barcode_label')
  }

  return (
    <Element>
      <Element.header>
        <Element.header.logo src={Images.rosieLogoColor}/>
        <Element.header.right>
          <Element.header.home src={Images.homeBlueBtn} />
          <Element.header.profile src={Images.avatar}/>
        </Element.header.right>
      </Element.header>

      <Element.form>
        <Element.photo>
          <Element.photoBG src={Images.itemCheckBG}/>
          <Element.photoImage src={State.images[0]}/>
        </Element.photo>
        <Element.title>
          We need some more information
        </Element.title>
        <Element.subtitle>
          Please scan the barcode and provide the model number to begin.
        </Element.subtitle>
        <Element.btnRow>
          <Element.btnTask type='button' onClick={() => navigateToScan(IMAGE_TYPE_BARCODE)}>
            <Element.btnContentLight>
              <span>Barcode</span>
              {getIcon(IMAGE_TYPE_BARCODE)}
            </Element.btnContentLight>
          </Element.btnTask>
          <Element.btnTask type='button' onClick={() => navigateToScan(IMAGE_TYPE_LABEL)}>
            <Element.btnContentLight>
              <span>Label</span>
              {getIcon(IMAGE_TYPE_LABEL)}
            </Element.btnContentLight>
          </Element.btnTask>
        </Element.btnRow>
        <Element.btnDark
          type='button'
          disabled={isSubmitDisabled()}
          onClick={() => navigate('thumb_select')}
        >
          <Element.btnContentDark>
            <span>Continue</span>
          </Element.btnContentDark>
        </Element.btnDark>

      </Element.form>
    </Element>
  )
}

export default ItemCheckScreen
