import {SUCCESS} from '../constants/constants'
import {State} from '../state/state'
import {
  CREATE_ASSET,
  GET_ASSET,
  GET_FULL_USER_LOCATIONS,
  INIT,
  LOGIN,
  LOGOUT,
  REGISTER,
  UPLOAD_IMAGE, 
  UPDATE_ASSET,
  CLEAR_BARCODE_LABEL,
  ADD_LOCATION,
  DELETE_LOCATION
} from './lib/action_types'

const INITIAL_STATE = {
  currentAsset: null,
  locations: [],  //initialized as empty
  identify: {},
  spaceTypes: [],
  user: null,
}

export default function (state = INITIAL_STATE, action) {
  if (action.type !== LOGIN) {console.log('ACTION', action) }
  console.log('CURRENT STATE', state)
  switch (action.type) {
    case `${CLEAR_BARCODE_LABEL}`:
      State.barcodeScanTaken = false
      State.labelScanTaken = false
      return log({ ...state, barcodeScanTaken: false, labelScanTaken: false })
    case `${CREATE_ASSET}-${SUCCESS}`:
      State.locations.push(action.data.locations)
      return log({ ...state, locations: action.data.locations })
    case `${INIT}-${SUCCESS}`:
      return log({ ...state, spaceTypes: action.data.spaceTypes })
    case `${LOGIN}-${SUCCESS}`:
      return log({ ...state, user: action.data })
    case `${LOGOUT}`:
      return log({ ...state, user: null })
    case `${GET_ASSET}-${SUCCESS}`:
      State.matchingAssetId = action.data.asset?.id
      State.assetThumbnailUrl = action.data.asset?.thumbnail
      return log({ ...state, currentAsset: action.data.asset })
    case `${GET_FULL_USER_LOCATIONS}-${SUCCESS}`:
      State.locations.push(action.data.locations)
      return log({ ...state, locations: action.data.locations })
    case `${REGISTER}-${SUCCESS}`:
      return log({ ...state, user: action.data })
    case `${UPDATE_ASSET}-${SUCCESS}`:
      State.locations.push(action.data.locations)
      return log({ ...state, currentAsset: null })
    case `${UPLOAD_IMAGE}`:
      State.currentAsset = null;
      return log({ ...state, currentAsset: null })
    case `${UPLOAD_IMAGE}-${SUCCESS}`:
      State.assetMatch = action.data.assetMatch
      State.matchingAssetId = action.data.asset_id
      State.imageUrls.push(action.data.imageUrl)
      return log({ ...state, identify: action.data })
    case ADD_LOCATION:             //Included this for adding a location
      return {
        ...state,
        locations: [...state.locations, action.payload],
      }
    case DELETE_LOCATION:      //Included for the future deletion option for a location
      return {
        ...state,
        locations: state.locations.filter(location => location.id !== action.payload),
      }
    default:
      return log(state)
  }
}

const log = (state) => {
  console.log('NEW STATE', state)
  return state;
}