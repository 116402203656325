import React, { useEffect, useState } from 'react'
import { ReactComponent as RosieLogo } from '../assets/svg/rosie_vision_logo.svg'
import Element from '../style/screens/login_screen_styles'
import { action } from '../state/lib/actions'
import { useSelector, useDispatch } from 'react-redux'
import { GET_FULL_USER_LOCATIONS, LOGIN } from '../state/lib/action_types'
import '../style/style.css'


const LoginScreen = ({ navigate }) => {
  const user = useSelector((state) => state.app.user)
  const [fields, setFields] = useState({
    email: '',
    password: '',
  })
  const dispatch = useDispatch()

  const login = () => {
    if (fields.email && fields.password) {
      dispatch(action({ type: LOGIN, data: fields }))
    }
  }

  const handleTextInputChange = (fieldName, text) => {
    const updatedFields = fields
    updatedFields[fieldName] = text
    setFields(updatedFields)
  }
  useEffect(() => {
    if (user) {
      const userFields = { userId: user?.id }
      dispatch(action({ type: GET_FULL_USER_LOCATIONS, data: userFields }))
      setTimeout(() => navigate('dashboard'), 1000)
    }
  }, [user])

  return (
    <div className='login'>
      <Element.header><RosieLogo /></Element.header>
      <Element.textInput
        type='text'
        placeholder='Email'
        onChange={e => handleTextInputChange('email', e.target.value)}
      />
      <Element.textInput
        type='password'
        placeholder='Password'
        onChange={e => handleTextInputChange('password', e.target.value)}
      />
      <Element.submit onClick={login}>Login</Element.submit>
      {/* {
        <Element.signUp>Don’t have an account?
          <Element.signUpButton onClick={() => navigate('register')}>Sign Up</Element.signUpButton>
        </Element.signUp>
      } */}

    </div>
  )
}

export default LoginScreen