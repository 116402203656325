import React, { useState } from 'react';
import Element from '../style/screens/new_location_screen_styles'; // Import the styled components
import { useDispatch, useSelector } from 'react-redux'; // Import useSelector to access Redux state
import { addLocation, action } from '../state/lib/actions'; // Import the addLocation action
import { GET_FULL_USER_LOCATIONS } from "../state/lib/action_types";

const NewLocationScreen = ({ navigate }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.app.user.id); // Get userId from Redux state
  const locations = useSelector((state) => state.app.locations);
  const [locationName, setLocationName] = useState('');
  const [address, setAddress] = useState('');
  const [image, setImage] = useState(null);
  const [error, setError] = useState(''); // State for error messages

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!locationName || !address) {
      setError('Both location name and address are required.');
      return;
    }

    if (!locations) {
        console.log("No locations found");
        return;
    }
    console.log("Adding new location:", { user_id: userId, name: locationName, street_address1: address });
    // Dispatch the action to add a new location
    dispatch(addLocation({ user_id: userId, name: locationName, street_address1: address })); // Updated payload

    // Reset form state
    setLocationName('');
    setAddress('');
    setImage(null); //removing image for now
    setError(''); // Clear any error messages

    // Navigate back to the dashboard
    dispatch(action({type: GET_FULL_USER_LOCATIONS, data: {userId: userId}}))
    navigate('dashboard');
  };

  const handleImageCapture = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set the captured image to state
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  return (
    <Element>
      <Element.form onSubmit={handleSubmit}>
        <Element.title>Add New Location</Element.title>
        
        {/* Error message */}
        {error && <Element.error>{error}</Element.error>}

        {/* Circular file input for capturing image */}
        <Element.fileInputContainer>
          <Element.fileInput
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleImageCapture}
          />
        </Element.fileInputContainer>

        {/* Optionally display the captured image */}
        {image && <Element.imagePreview src={image} alt="Captured" />}

        {/* Input for location name */}
        <label htmlFor="locationName">Location Name</label>
        <Element.input
          id="locationName"
          type="text"
          placeholder="Enter location name"
          value={locationName}
          onChange={(e) => setLocationName(e.target.value)}
          required
        />

        {/* Input for address */}
        <label htmlFor="address">Address</label>
        <Element.input
          id="address"
          type="text"
          placeholder="Enter address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        />

        <Element.button type="submit">Add Location</Element.button>
        <Element.button type="button" onClick={() => navigate('dashboard')}>
          Cancel
        </Element.button>
      </Element.form>
    </Element>
  );
};

export default NewLocationScreen;
